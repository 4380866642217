import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="नामानुभव ग्रंथ - मुखपृष्ठ" />
        <HeaderGeneric title="नामानुभव ग्रंथ" adhyaay='मुखपृष्ठ' />
        <div id="main">
          <section id="content" className="main">
            <p>
              <center>
                हरी हरी हरी हरी । वास कर माझ्या घरी ॥< br />
रामकृष्ण हरी । भवसागर तारी ॥< br />
योगेश्वर माझ्या घरी । चिंता न राहिली उरी ॥< br />
विठ्ठल रखुमाई घरी । पंढरीच आली घरी ॥< br />
                < br />
			-: नामानुभव :-< br />
							- गणामहाराज< br />
                < br />
नाम म्हणजे माऊली । सदा कृपेची सावली ॥< br />
पांडुरंग गुरू माऊली । कृपा छत्री लाभली ॥< br />
गजानन माझी माऊली । भक्तासाठी धावली ॥< br />
समर्थ माझी माऊली । दत्त कृपेची सावली ॥< br />
                < br />
              </center>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
